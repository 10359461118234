import styleClass from "./style.module.scss"

interface HeaderProps {
    school: string,
}


export function Header({ school }: HeaderProps) {

    return (
        <header>
            <div className={styleClass.banner}>

                <div className={styleClass.bannerImg + " " + styleClass.plenoBanner} 
                    style={{backgroundImage:  'url(' + require(`../../assets/img/banner/banner-${school}.png`) + ')'}}></div>

            </div>
        </header>
    )
}