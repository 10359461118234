import { FormEvent, useState } from "react"
import styleClass from "./style.module.scss"
import copy from 'copy-to-clipboard';
import InputMask from 'react-input-mask'

interface BarCodeProps{
    school: string,
    agency: string,
    account: string
}

export function BarCode({school, agency, account}:BarCodeProps){
    const [code, setCode] = useState('')
    const [isValid, setIsValid] = useState<boolean>()
    const boletoModule = require('boleto-brasileiro-validator')
    const codeFinal = code.replace(/ /g, '').replace('.',"").replace('.',"").replace('.',"")
    const btnEi = {
        background: '#0c6c87'
    }
    const btnPleno ={
        background: '#21B5EA'
    }
    const borderPleno ={
        border: '1px solid rgba(99, 36, 127, 0.38)',
    }
    const borderEi ={
        border: '1px solid rgba(1, 111, 166, 0.54)',
    }
/*     const [codePrice, setCodePrice] = useState<string>()
    const [codeDate, setCodeDate] = useState<string>() */
    const {boleto} = boletoModule


/*     function getData(code:string){
        var vencimento = Number(code.substring(code.length - 14, code.length - 10));
        console.log(vencimento)
        var date = new Date('10/07/1997');
        date.setTime(date.getTime() + (vencimento * 24 * 60 * 60 * 1000));

        setCodeDate(("0" + (date.getDate())).slice(-2) + '/' + ("0" + (date.getMonth() + 1)).slice(-2) + '/' + date.getFullYear());
    }
    function getPrice(code : string){
        const valueNumber = parseFloat(code.substring(code.length - 10, code.length)).toString()
        if (valueNumber.length == 2) { 
            const priceFinal = "0," + valueNumber;
            setCodePrice(priceFinal)
          }else if (valueNumber.length == 1) { 
            const priceFinal = "0,0" + valueNumber;
            setCodePrice(priceFinal)
          } 
          else if(valueNumber.length >=6 ){
            const priceFinal = valueNumber.substring(0, valueNumber.length - 5) + "."+ valueNumber.substring(1, valueNumber.length -2) + "," + valueNumber.substring(valueNumber.length -2, valueNumber.length);
            setCodePrice(priceFinal)
          }
          else { 
            const priceFinal = valueNumber.substring(0, valueNumber.length -2) + "," + valueNumber.substring(valueNumber.length -2, valueNumber.length);
            setCodePrice(priceFinal)
          }
    } */

    
    function handleSubmit(event: FormEvent){
        event.preventDefault()
        debugger
        if(boleto(codeFinal)){
            if(codeFinal.slice(0, 3) === '341'){
                if(codeFinal.slice(17, 20) === agency.slice(0,3) && codeFinal.slice(21, 22) === agency.slice(3,4)){
                    if(codeFinal.slice(22, 28) === account){
                        setIsValid(true)
                        /* getData(code)
                        getPrice(code) */
                    }
                    else setIsValid(false)
                }
                else {setIsValid(false)}
            }
            else setIsValid(false)
        }
        else{
            setIsValid(false)
        }
    }
    const copyCode = () => {
        copy(codeFinal);
    }
    function isMsg(){
       if(isValid !== undefined){
            setIsValid(undefined)
       }
    }
    return (
        <div className={styleClass.barCode}>
            <div className="container">
                <form onSubmit={handleSubmit}>
                    <div className={styleClass.title}>
                        Cole e/ou digite o código de barras do seu boleto no campo abaixo e verifique se as informações e valores são compatíveis com os dados de nossos pagamentos!
                    </div>
                    <div className={styleClass.input}>
                        <InputMask mask={'99999.99999 99999.999999 99999.999999 9 99999999999999'}  type="text" onKeyUp={isMsg} style={school === 'pleno'? borderPleno: borderEi} required id="barCode" name="barCode" placeholder="000-000-000-000 (cole aqui...)" value={code} onChange={e => setCode(e.target.value)}/>
                        <div className={styleClass.buttons}>
                            <div className={styleClass.copyContainer}>
                                <div className={styleClass.hoverClone}><span>Colar</span><div className={styleClass.arrowBottom}><img src="/arrowBottom.png" alt="" /></div></div>
                                <button type="button" onClick={copyCode} id="copyButton" style={school === 'pleno'? borderPleno: borderEi} className={styleClass.clone}><img src="clone.png" alt="" /></button>
                            </div>
                            <div className={styleClass.confirmnContainer}>
                                <div className={styleClass.hoverConfirmn}><span>Enviar</span><div className={styleClass.arrowBottom}><img src="/arrowBottom.png" alt="" /></div></div>
                                <button type="submit"  className={styleClass.confirmn } style={school === 'pleno'? btnPleno: btnEi}><img src="confirmn.png" alt="" /></button>
                            </div>
                        </div>
                    </div>
                </form>
                {isValid !== undefined && (
                <div className={styleClass.alert}>
                    {isValid ? 
                    (<div className={styleClass.contentMessage }><h2 className={styleClass.true}>Código validado!</h2>
                    <p>Pode efetuar o pagamento do seu boleto com segurança.</p></div>)
                    :(<div className={styleClass.contentMessage }><h2 className={styleClass.false}>Código inválido!</h2>
                    <p>Não efetue o pagamento deste boleto.</p>
                        <p>Entre em contato para lhe enviarmos um novo.</p></div>)}    
                </div>)}
                
            </div>
        </div>
    )
}