import { BrowserRouter, Route } from "react-router-dom";
import School from "../pages/School";
import schools from "../schools.json"

export default function Routes() {
    const href = window.location.href
    const school = schools.find(item => item.href === href) || schools[0];

    const favicon = document.getElementById("favicon") as HTMLAnchorElement | null;

    if (favicon != null)
        favicon.href = `/favicon/favicon-${school.alias}.png`;

    return (
        <BrowserRouter>
            <Route
                render={props => {
                    return <School school={school} />
                }}
            />

        </BrowserRouter>
    )
}