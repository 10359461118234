import styleClass from "./style.module.scss"

interface FooterProps{
    school: string,
    fone?: string
}
export function Footer({school, fone}:FooterProps){

    if(school === "pleno"){
        return(
            <div className={styleClass.footer + ` ${school}`}>
                <div className={styleClass.footerContent + " container"}>
                    <div className={styleClass.logo}>
                        <img src={require(`../../assets/img/logo/Logo-${school}.png`)} alt="" />
                    </div>
                    <div className={styleClass.text}>
                        Em caso de incompatibilidade de informações entre em contato imediatamente com nossa equipe!
                     </div>
                    <div className={styleClass.content} style={{paddingBottom: '25px'}}>
                    <h2>Entrar em contato</h2>
                    <div><img src="/fone.png" alt="" /><span>{fone}</span></div>
                    </div>
                </div>
            <div className={styleClass.footerBottom + " plenoBottom"}>
                <a target="_blank" href="https://www.avanz.com.br">
                    <img src="/logo_avanz.png" />
                </a>
            </div>
            </div>
        )
    }
    return (
        <div className={styleClass.footer + ` ${school}`}>
            <div className={styleClass.footerContent + " container"}>
{/*                 <div className={styleClass.logo}>
                    <img src={require(`../../assets/img/logo/Logo-${school}.png`)} alt="" />
                </div> */}
                <div className={styleClass.text}>
                    Em caso de incompatibilidade de informações entre em contato imediatamente com nossa equipe!
                </div>
                <div className={styleClass.content}>
                    <h2>Entrar em contato</h2>
                    <div><img src="/fone.png" alt="" /><span>{fone}</span></div>
                </div>
            </div>
            <div className={styleClass.footerBottom + " eiBottom"}>
                <a target="_blank" href="https://www.avanz.com.br">
                    <img src="/logo_avanz.png" />
                </a>
            </div>
        </div>
    )
}