import React from "react"
import { BarCode } from "../../components/BarCode"
import { Footer } from "../../components/Footer"
import { Header } from "../../components/Header"
interface SchoolProps {
    school: {
        name: string,
        href: string,
        alias : string,
        fone?: string,
        agency: string,
        account: string
    }
}

const School: React.FC<SchoolProps> = ({ school }) => {

    document.title = school.name
    return (
        <>
            <Header school={school.alias} />
            <BarCode school={school.alias} account={school.account} agency={school.agency}/>
            <Footer school={school.alias} fone={school.fone}  />
        </>
    )
}

export default School